import React from "react"
import ColeAvatar from "./ColeAvatar";
import DanAvatar from "./DanAvatar";
import { rhythm } from "../utils/typography"

const AUTHOR_DATA = {
  "Cole Simmons": {
    "bio": (
      <p>
        Written by Cole Simmons who lives in Los Angeles and is the CTO of {' '}
        <a href="https://www.abstractcre.com">Abstract</a>.
        <a href={`https://twitter.com/colersimmons`}>
          You should follow him on Twitter
        </a>
      </p>
    ),
  },
  "Dan Simmons": {
    "twitter": "@simmons_dan",
    "bio": (
      <p>
        Written by Dan Simmons who lives in Denver and enjoys long walks
        on the beach and shredding some fresh pow.
        <a href={`https://twitter.com/simmons_dan`}>
          You should follow him on Twitter
        </a>
      </p>
    ),
  },
};

const Bio = ({ author }) => {
  const { twitter, bio } = AUTHOR_DATA[author];

  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      {author === "Cole Simmons" ? <ColeAvatar alt={author} /> : null}
      {author === "Dan Simmons" ? <DanAvatar alt={author} /> : null}
      {bio}
    </div>
  )
}

export default Bio
