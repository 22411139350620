import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { rhythm } from "../utils/typography"

const query = graphql`
  query {
    avatar: file(absolutePath: { regex: "/cole.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const ColeAvatar = ({ alt }) =>
  <StaticQuery
    query={query}
    render={data => (
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={alt}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
    )}
  />

export default ColeAvatar;
